import React from "react"
import PropTypes from "prop-types"

import { Section, Container, Title, Date, Link } from "./styles"

import SectionTitle from "../../UI/SectionTitle"

const News = ({ data }) => {
  // Props
  const { news_title } = data.primary

  return (
    <Section id="news">
      <SectionTitle light>{news_title && news_title.text}</SectionTitle>
      <Container>
        {data.items.map((item, i) => {
          const { news_item_title, news_item_date, news_item_link } = item
          return (
            <Link
              key={`news-article-${i}`}
              href={news_item_link && news_item_link.url}
            >
              <Date>{news_item_date}</Date>
              <Title>{news_item_title && news_item_title.text}</Title>
            </Link>
          )
        })}
      </Container>
    </Section>
  )
}

News.propTypes = {
  data: PropTypes.object,
}

export default News
