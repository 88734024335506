import styled from "@emotion/styled"
import { breakpoints } from "../../../utils/styles"

export const Button = styled.button`
  background: transparent;
  color: ${({ theme }) => theme.white};
  font-family: Nunito, sans-serif;
  font-weight: 700;
  font-size: 16px;
  padding: 12px;
  min-width: 160px;
  outline: none;
  border: 2px solid ${({ theme }) => theme.white};
  border-radius: 30px;
  line-height: 24px;
  cursor: pointer;

  &:active, :focus {
    color: ${({ theme }) => theme.black}
    background: ${({ theme }) => theme.white};
  }

  @media (min-width: ${breakpoints.xl}px){
    padding: 16px;
  }
`
