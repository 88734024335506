import React, { useLayoutEffect, useRef, useState } from "react"
import PropTypes from "prop-types"

import {
  Container,
  ImageContainer,
  TextSection,
  TextContainer,
  Text,
  MoleculeGrid,
  MoleculeOne,
  MoleculeTwo,
  MoleculeThree,
  MoleculeFour,
  MoleculeFive,
  FallOne,
  FallTwo,
  FallThree,
  FallFour,
  FallFive,
} from "./styles"

import SectionTitle from "../../UI/SectionTitle"
import Molecules from "../../../assets/molecules.svg"
import Molecule1 from "../../../assets/molecule1.svg"
import Molecule2 from "../../../assets/molecule2.svg"
import Molecule3 from "../../../assets/molecule3.svg"
import Molecule4 from "../../../assets/molecule4.svg"

const TwoColGrid = ({ title, text, opposite }) => {
  // State
  const [show, doShow] = useState({
    itemOne: false,
    itemTwo: false,
    itemThree: false,
    itemFour: false,
    itemFive: false,
  })

  // Refs
  const refOne = useRef(null),
    refTwo = useRef(null),
    refThree = useRef(null),
    refFour = useRef(null),
    refFive = useRef(null)

  useLayoutEffect(() => {
    const topPos = element => element.getBoundingClientRect().top

    const div1Pos = topPos(refOne.current),
      div2Pos = topPos(refTwo.current),
      div3Pos = topPos(refThree.current),
      div4Pos = topPos(refFour.current),
      div5Pos = topPos(refFive.current)

    const onScroll = () => {
      const scrollPos = window.scrollY + window.innerHeight
      div1Pos < scrollPos && doShow(state => ({ ...state, itemOne: true }))
      div2Pos < scrollPos && doShow(state => ({ ...state, itemTwo: true }))
      div3Pos < scrollPos && doShow(state => ({ ...state, itemThree: true }))
      div4Pos < scrollPos && doShow(state => ({ ...state, itemFour: true }))
      div5Pos < scrollPos && doShow(state => ({ ...state, itemFive: true }))
    }

    window.addEventListener("scroll", onScroll)
    return () => window.removeEventListener("scroll", onScroll)
  }, [])

  // Text
  const textSection = (
    <TextSection opposite={opposite}>
      <TextContainer>
        <SectionTitle>{title}</SectionTitle>
        <Text>{text}</Text>
      </TextContainer>
    </TextSection>
  )

  // Image
  const imageSection = (
    <ImageContainer>
      <FallOne animate={show.itemOne}>
        <MoleculeOne
          src={Molecule1}
          alt="molecule1"
          animate={show.itemOne}
          ref={refOne}
        />
      </FallOne>
      <FallTwo animate={show.itemTwo}>
        <MoleculeTwo
          src={Molecule2}
          alt="molecule2"
          animate={show.itemTwo}
          ref={refTwo}
        />
      </FallTwo>
      <FallThree animate={show.itemThree}>
        <MoleculeThree
          src={Molecule3}
          alt="molecule3"
          animate={show.itemThree}
          ref={refThree}
        />
      </FallThree>
      <FallFour animate={show.itemFour}>
        <MoleculeFour
          src={Molecule4}
          alt="molecule4"
          animate={show.itemFour}
          ref={refFour}
        />
      </FallFour>
      <FallFive animate={show.itemFive}>
        <MoleculeFive
          src={Molecule1}
          alt="molecule5"
          animate={show.itemFive}
          ref={refFive}
        />
      </FallFive>
      <MoleculeGrid src={Molecules} alt="Molecules" />
    </ImageContainer>
  )

  return (
    <Container id="technology">
      {opposite ? imageSection : textSection}
      {opposite ? textSection : imageSection}
    </Container>
  )
}

TwoColGrid.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  opposite: PropTypes.bool,
}

export default TwoColGrid
