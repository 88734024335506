import styled from "@emotion/styled"
import { breakpoints } from "../../../utils/styles"

export const Title = styled.h2`
  color: ${({ theme, light }) => (light ? theme.white : theme.black)};
  font-family: Nunito, sans-serif;
  font-weight: 700;
  font-size: 32px;
  z-index: 1;

  @media (max-width: ${breakpoints.m}px) {
    font-size: 24px;
  }
`
