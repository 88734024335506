import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Splash from "../components/homepage/Splash"
import TwoColGrid from "../components/homepage/TwoColGrid"
import Timeline from "../components/homepage/Timeline"
import Team from "../components/homepage/Team"
import News from "../components/homepage/News"
import Contact from "../components/homepage/Contact"
import SEO from "../components/seo"

const IndexPage = ({ data }) => {
  const page = data.prismicHomepage.data

  const body = page.body.map((slice, index) => {
    const key = `slice-${index}`
    switch (slice.slice_type) {
      case "process":
        return (
          <TwoColGrid
            key={key}
            opposite
            title={slice.primary.process_title.text}
            text={slice.primary.process_text.text}
          />
        )
      case "timeline":
        return <Timeline key={key} data={slice} />
      case "news":
        return <News key={key} data={slice} />
      case "team":
        return <Team key={key} data={slice} />
      case "contact":
        return <Contact key={key} data={slice.primary} />
      default:
        return null
    }
  })

  return (
    <Layout>
      <SEO />
      <Splash title={page.page_title} social={page.social} />
      {body}
    </Layout>
  )
}

export const pageQuery = graphql`
  query IndexQuery {
    prismicHomepage {
      data {
        social {
          social_icon {
            alt
            url
          }
          social_icon_link {
            url
          }
        }
        body {
          ... on PrismicHomepageBodyContact {
            slice_type
            primary {
              contact_button_text
              contact_field_email
              contact_field_message
              contact_field_name
              contact_title {
                text
              }
              contact_background_image {
                alt
                url
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 2000) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
          ... on PrismicHomepageBodyNews {
            items {
              news_item_date
              news_item_link {
                url
              }
              news_item_title {
                text
              }
            }
            primary {
              news_title {
                text
              }
            }
            slice_type
          }
          ... on PrismicHomepageBodyProcess {
            slice_type
            primary {
              process_text {
                text
              }
              process_title {
                text
              }
            }
          }
          ... on PrismicHomepageBodyTeam {
            items {
              team_member_link {
                url
                link_type
              }
              team_member_name {
                text
              }
              team_member_portrait {
                url
                alt
              }
              team_member_position {
                text
              }
            }
            primary {
              team_title {
                text
              }
            }
            slice_type
          }
          ... on PrismicHomepageBodyTimeline {
            items {
              timeline_item_date
              timeline_item_text {
                text
              }
            }
            primary {
              timeline_title {
                text
              }
            }
            slice_type
          }
        }
        page_title {
          text
        }
      }
    }
  }
`

export default IndexPage
