import React from "react"
import PropTypes from "prop-types"

import { navigate } from "gatsby"
import { Button } from "./styles"

const RaisedButton = ({ text, link, type }) => {
  // Link
  const action = () => link && navigate(link)

  return (
    <Button type={type || "button"} onClick={action}>
      {text}
    </Button>
  )
}

RaisedButton.propTypes = {
  text: PropTypes.string,
  link: PropTypes.string,
  type: PropTypes.string,
}

export default RaisedButton
