import styled from "@emotion/styled"
import { keyframes, css } from "@emotion/core"
import { breakpoints } from "../../../utils/styles"

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  z-index: 1;

  @media (max-width: ${breakpoints.l}px) {
    grid-template-columns: repeat(1, 1fr);
  }
`

export const ImageContainer = styled.div`
  height: 600px;
  overflow: hidden;
  position: relative;
  background: ${({ theme }) => theme.primary};

  @media (max-width: ${breakpoints.l}px) {
    order: 1;
  }
`

export const TextSection = styled.div`
  display: flex;
  background: ${({ theme }) => theme.white};
  color: ${({ theme }) => theme.black};
  justify-content: center;
  z-index: 1;

  @media (max-width: ${breakpoints.l}px) {
    order: 2;
  }

  @media (min-width: ${breakpoints.l}px) {
    background: ${({ theme }) => theme.white};
  }
`

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 32px;
  max-width: 600px;
  height: 500px;

  @media (max-width: ${breakpoints.l}px) {
    max-width: 100%;
    padding: 32px 24px;
    height: 400px;
  }

  @media (min-width: ${breakpoints.xl}px) {
    max-width: 800px;
    padding: 32px 64px;
  }
`

export const Text = styled.p`
  margin: 0;
`

export const MoleculeGrid = styled.img`
  position: absolute;
  left: -108px;
  bottom: -112px;
`

const fallX = x => keyframes`
  0% {
    visibility: visible;
    transform: translateX(${x}px), rotate(0deg);
  }
  100% {
    visibility: visible;
    transform: translateX(${x + 200}px) rotate(360deg);
  }
`

const fallY = y => keyframes`
  0% {
    transform: translateY(${y}px);
  }
  40% {
    transform: translateY(${y - 200}px);
  }
  100% {
    transform: translateY(${y - 100}px);
  }
`

export const FallOne = styled.div`
  animation: ${({ animate }) =>
    animate
      ? css`
          ${fallY(400)} 3s forwards ease-in
        `
      : "none"};
`

export const MoleculeOne = styled.img`
  visibility: hidden;
  animation: ${({ animate }) =>
    animate
      ? css`
          ${fallX(120)} 3s forwards ease-in;
        `
      : "none"};
`

export const FallTwo = styled.div`
  animation: ${({ animate }) =>
    animate
      ? css`
          ${fallY(100)} 3s forwards ease-in
        `
      : "none"};
`

export const MoleculeTwo = styled.img`
  visibility: hidden;
  animation: ${({ animate }) =>
    animate
      ? css`
          ${fallX(60)} 3s forwards ease-in;
        `
      : "none"};
`

export const FallThree = styled.div`
  animation: ${({ animate }) =>
    animate
      ? css`
          ${fallY(150)} 3s forwards ease-in
        `
      : "none"};
`

export const MoleculeThree = styled.img`
  visibility: hidden;
  animation: ${({ animate }) =>
    animate
      ? css`
          ${fallX(20)} 3s forwards ease-in;
        `
      : "none"};
`

export const FallFour = styled.div`
  animation: ${({ animate }) =>
    animate
      ? css`
          ${fallY(180)} 3s forwards ease-in
        `
      : "none"};
`

export const MoleculeFour = styled.img`
  visibility: hidden;
  animation: ${({ animate }) =>
    animate
      ? css`
          ${fallX(60)} 3s forwards ease-in;
        `
      : "none"};
`

export const FallFive = styled.div`
  animation: ${({ animate }) =>
    animate
      ? css`
          ${fallY(-200)} 3s forwards ease-in
        `
      : "none"};
`

export const MoleculeFive = styled.img`
  visibility: hidden;
  animation: ${({ animate }) =>
    animate
      ? css`
          ${fallX(-100)} 3s forwards ease-in;
        `
      : "none"};
`
