import styled from "@emotion/styled"
import { breakpoints, dimensions } from "../../../utils/styles"

export const Section = styled.section`
  background: ${({ theme }) => theme.black};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 64px 32px;

  @media (max-width: ${breakpoints.l}px) {
    padding: 48px 24px;
  }
`

export const Container = styled.div`
  color: ${({ theme }) => theme.white};
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 64px 0;
  gap: 32px 96px;
  max-width: ${dimensions.contentWidth};

  @media (max-width: ${breakpoints.l}px) {
    grid-template-columns: repeat(1, 1fr);
    padding: 48px 0;
  }
`

export const Link = styled.a`
  color: ${({ theme }) => theme.white};
  text-decoration: none;
`

export const Date = styled.p`
  font-size: 14px;
  margin-bottom: 8px;
`

export const Title = styled.h3`
  font-size: 24px;
  margin: 0;
`
