import styled from "@emotion/styled"
import { breakpoints } from "../../../utils/styles"

export const Section = styled.section`
  background: ${({ theme }) => theme.secondary};
  color: ${({ theme }) => theme.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 64px 32px;

  @media (max-width: ${breakpoints.l}px) {
    padding: 48px 0;
  }
`

export const Container = styled.div`
  display: flex;
  padding: 48px 24px;

  @media (max-width: ${breakpoints.l}px) {
    display: none;
  }
`

export const ContainerMobile = styled.div`
  display: none;
  padding: 48px 24px;

  @media (max-width: ${breakpoints.l}px) {
    display: flex;
  }
`

export const TimelineItemColumn = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

export const TimelineColumn = styled.div`
  width: 96px;
  display: flex;
  flex-direction: column;
`

export const TimelineGap = styled.div`
  width: 80px;
  height: 80px;
`

export const TimelineIcon = styled.div`
  position: relative;
  height: 80px;
`

export const TimelineLeft = styled.img`
  position: absolute;
  top: 32px;
  left: 0;
  margin: 0;
`

export const TimelineRight = styled.img`
  position: absolute;
  top: 32px;
  right: 0;
  margin: 0;
`

export const TimelineContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: ${({ left }) => (left ? 0 : "24px")};
  padding-right: ${({ left }) => (left ? "24px" : 0)};
  text-align: ${({ left }) => (left ? "right" : "left")};
  height: 80px;

  @media (max-width: ${breakpoints.l}px) {
    padding-right: 0;
    text-align: left;
  }
`

export const Date = styled.p`
  font-size: 14px;
  margin: 0;

  @media (max-width: ${breakpoints.l}px) {
    font-size: 12px;
  }
`

export const Title = styled.h3`
  font-size: 24px;
  margin-bottom: 8px;

  @media (max-width: ${breakpoints.l}px) {
    font-size: 16px;
  }
`
