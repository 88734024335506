import React, { useState } from "react"
import PropTypes from "prop-types"
import BackgroundImage from "gatsby-background-image"
import { isMobile } from "react-device-detect"

import {
  Overlay,
  Container,
  Form,
  Label,
  FormTop,
  FormTopInput,
  FormBottom,
  FormAction,
} from "./styles"

import Button from "../../UI/Button"
import InputField from "../../UI/InputField"
import SectionTitle from "../../UI/SectionTitle"

const Contact = ({ data }) => {
  // Props
  const {
    contact_title,
    contact_field_name,
    contact_field_email,
    contact_field_message,
    contact_button_text,
    contact_background_image,
  } = data

  // State
  const [validInput, setValidInput] = useState({
    name: false,
    email: false,
    message: false,
  })

  // Handle change
  const handleChange = event => {
    const { name, value } = event.target
    setValidInput({
      ...validInput,
      [name]: name === "email" ? emailIsValid(value) : Boolean(value.length),
    })
  }

  // Email validation
  const emailIsValid = email => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)

  return (
    <BackgroundImage
      Tag="section"
      id="contact"
      fluid={
        contact_background_image &&
        contact_background_image.localFile.childImageSharp.fluid
      }
      style={!isMobile ? { backgroundAttachment: "fixed" } : null}
    >
      <Overlay>
        <Container>
          <SectionTitle light>{contact_title.text}</SectionTitle>
          <Form action="https://formspree.io/xaypjklb" method="POST">
            <FormTop>
              <FormTopInput>
                <Label htmlFor="name">{contact_field_name}</Label>
                <InputField
                  id="name"
                  placeholder="Your name"
                  onChange={handleChange}
                  valid={validInput.name}
                  required
                />
              </FormTopInput>
              <FormTopInput right>
                <Label htmlFor="email">{contact_field_email}</Label>
                <InputField
                  id="email"
                  type="email"
                  placeholder="Your email"
                  onChange={handleChange}
                  valid={validInput.email}
                  required
                />
              </FormTopInput>
            </FormTop>
            <FormBottom>
              <Label htmlFor="message">{contact_field_message}</Label>
              <InputField
                id="message"
                type="textarea"
                placeholder="Your message"
                onChange={handleChange}
                valid={validInput.message}
                required
              />
            </FormBottom>
            <FormAction>
              <Button type="submit" text={contact_button_text} />
            </FormAction>
          </Form>
        </Container>
      </Overlay>
    </BackgroundImage>
  )
}

Contact.propTypes = {
  data: PropTypes.object,
}

export default Contact
