import React, { useState, Fragment } from "react"
import PropTypes from "prop-types"

import {
  Section,
  Container,
  ParticleLayer,
  TeamMembers,
  Hexagon,
  HexTop,
  HexBottom,
  HexaGap,
  HexaRow,
  MemberContainer,
  MemberName,
  MemberPosition,
} from "./styles"

import useWidth from "../../hooks/UseWidth"
import ParticlesConfig from "./particles.json"
import SectionTitle from "../../UI/SectionTitle"

const Team = ({ data }) => {
  const width = useWidth()
  const mobile = Boolean(width < 992)

  // Props
  const { team_title } = data.primary

  // State
  const [hover, setHover] = useState(data.items.map(_ => false))

  // Actions
  const leave = () => setHover(data.items.map(_ => false))
  const enter = index => {
    const newHover = [...hover]
    newHover[index] = true
    setHover(newHover)
  }

  return (
    <Section id="team">
      <Container>
        <SectionTitle>{team_title.text}</SectionTitle>
        <TeamMembers>
          {data.items.map((item, i) => {
            const {
              team_member_portrait,
              team_member_link,
              team_member_name,
              team_member_position,
            } = item

            const hexagon = (
              <HexaRow
                onMouseEnter={() => enter(i)}
                onTouchStart={() => enter(i)}
                onMouseLeave={leave}
                onTouchEnd={leave}
              >
                {mobile && i % 2 === 0 && <HexaGap />}
                <Hexagon
                  image={team_member_portrait && team_member_portrait.url}
                  href={team_member_link && team_member_link.url}
                  overlay={hover[i]}
                >
                  <MemberContainer show={hover[i]}>
                    <MemberName>
                      {team_member_name && team_member_name.text}
                    </MemberName>
                    <MemberPosition>
                      {team_member_position && team_member_position.text}
                    </MemberPosition>
                  </MemberContainer>
                  <HexTop />
                  <HexBottom />
                </Hexagon>
                {mobile && i % 2 !== 0 && <HexaGap />}
              </HexaRow>
            )

            if (mobile) {
              return <div key={`team-member-${i}`}>{hexagon}</div>
            }
            return (
              <Fragment key={`team-member-${i}`}>
                {hexagon}
                {(i - 2) % 5 === 0 && <HexaGap half left />}
                {(i + 1) % 5 === 0 && <HexaGap half right />}
              </Fragment>
            )
          })}
        </TeamMembers>
      </Container>
      <ParticleLayer params={ParticlesConfig} />
    </Section>
  )
}

Team.propTypes = {
  data: PropTypes.object,
}

export default Team
