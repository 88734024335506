import React from "react"
import PropTypes from "prop-types"

import {
  Container,
  Video,
  Content,
  Overlay,
  Title,
  ArrowDown,
  SocialIcon,
  SocialContainer,
  SocialLink,
} from "./styles"

import HexagonIcon from "../../UI/HexagonIcon"

import ArrowIcon from "../../../assets/arrow-down.svg"
import BackupImage from "../../../assets/garbageaerial.jpg"

const Splash = ({ title, social }) => {
  return (
    <Container>
      <Video
        autoPlay
        muted
        loop
        playsInline
        src="https://scindo-assets.s3.eu-central-1.amazonaws.com/plastic.mp4"
        poster={BackupImage}
        type="video/mp4"
      />
      <Overlay>
        <SocialContainer>
          {social.map((item, index) => (
            <SocialLink
              key={`social-${index}`}
              href={item.social_icon_link.url}
            >
              <HexagonIcon>
                <SocialIcon
                  src={item.social_icon.url}
                  alt={item.social_icon.alt}
                />
              </HexagonIcon>
            </SocialLink>
          ))}
        </SocialContainer>
      </Overlay>
      <Content>
        <Title>{title.text}</Title>
        <ArrowDown src={ArrowIcon} alt="Down" />
      </Content>
    </Container>
  )
}

Splash.propTypes = {
  title: PropTypes.object,
  social: PropTypes.array,
}

export default Splash
