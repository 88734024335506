import styled from "@emotion/styled"
import { breakpoints } from "../../../utils/styles"

export const Overlay = styled.div`
  background: rgba(33, 39, 56, 0.6);
  display: flex;
  justify-content: center;
  padding: 64px 32px;

  @media (max-width: ${breakpoints.l}px) {
    padding: 48px 24px;
  }
`

export const Container = styled.div`
  max-width: 800px;
  display: flex;
  align-items: center;
  flex-direction: column;
  flex: 1;
`

export const Form = styled.form`
  width: 100%;
  margin-bottom: 0;
`

export const Label = styled.label`
  display: flex;
  color: ${({ theme }) => theme.white};
  font-family: Nunito, sans-serif;
  font-weight: 500;
  margin-bottom: 8px;
`

export const FormTop = styled.div`
  display: flex;

  @media (max-width: ${breakpoints.s}px) {
    flex-direction: column;
  }
`

export const FormTopInput = styled.div`
  flex: 1;
  margin-bottom: 24px;
  margin-right: ${({ right }) => (right ? "0" : "12px")};
  margin-left: ${({ right }) => (right ? "12px" : "0")};

  @media (max-width: ${breakpoints.s}px) {
    margin-right: 0;
    margin-left: 0;
  }
`

export const FormBottom = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
`

export const FormAction = styled.div`
  text-align: center;

  @media (max-width: ${breakpoints.s}px) {
    text-align: center;
  }
`
