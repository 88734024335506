import React from "react"
import PropTypes from "prop-types"
import { isMobile } from "react-device-detect"

import {
  Section,
  Container,
  ContainerMobile,
  Date,
  Title,
  TimelineColumn,
  TimelineItemColumn,
  TimelineLeft,
  TimelineRight,
  TimelineContent,
  TimelineIcon,
  TimelineGap,
} from "./styles"

import LineLeft from "../../../assets/line-left.svg"
import LineRight from "../../../assets/line-right.svg"

import useWidth from "../../hooks/UseWidth"
import SectionTitle from "../../UI/SectionTitle"

const Timeline = ({ data }) => {
  const width = useWidth()
  const mobile = isMobile || Boolean(width < 992)
  const length = data.items.length

  // Props
  const { timeline_title } = data.primary

  // Timeline column
  const timeLine = (
    <TimelineColumn>
      {data.items.map((_, i) => {
        let leftIcon, rightIcon
        if (i % 2 === 0) {
          leftIcon = <TimelineLeft src={LineLeft} alt="timeline1" />
        } else if (i !== length - 1) {
          rightIcon = <TimelineRight src={LineRight} alt="timeline2" />
        }

        return (
          <TimelineIcon key={`timeline-${i}`}>
            {leftIcon}
            {rightIcon}
          </TimelineIcon>
        )
      })}
    </TimelineColumn>
  )

  // Item columns
  let mobileItems = [],
    leftItems = [],
    rightItems = []
  data.items.forEach((item, i) => {
    const { timeline_item_date, timeline_item_text } = item

    const listItem = (
      <TimelineContent key={`timeline-${i}`} left={i % 2 !== 0}>
        <Title>{timeline_item_text && timeline_item_text.text}</Title>
        <Date>{timeline_item_date}</Date>
      </TimelineContent>
    )

    if (mobile) {
      mobileItems.push(listItem)
    } else {
      if (i % 2 !== 0) {
        leftItems.push(<TimelineGap key={`gap-${i}`} />)
        leftItems.push(listItem)
      } else {
        rightItems.push(listItem)
        rightItems.push(<TimelineGap key={`gap-${i}`} />)
      }
    }
  })

  return (
    <Section id="timeline">
      <SectionTitle light>{timeline_title && timeline_title.text}</SectionTitle>
      <Container>
        <TimelineItemColumn>{leftItems}</TimelineItemColumn>
        {timeLine}
        <TimelineItemColumn>{rightItems}</TimelineItemColumn>
      </Container>
      <ContainerMobile>
        {timeLine}
        <TimelineItemColumn>{mobileItems}</TimelineItemColumn>
      </ContainerMobile>
    </Section>
  )
}

Timeline.propTypes = {
  data: PropTypes.object,
}

export default Timeline
