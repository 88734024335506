import React from "react"
import PropTypes from "prop-types"

import { Title } from "./styles"

const SectionTitle = ({ children, light }) => {
  return <Title light={light}>{children}</Title>
}

SectionTitle.propTypes = {
  children: PropTypes.string.isRequired,
  light: PropTypes.bool,
}

export default SectionTitle
