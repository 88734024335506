import styled from "@emotion/styled"
import Particles from "react-particles-js"

import { breakpoints, dimensions } from "../../../utils/styles"

export const Section = styled.section`
  background: ${({ theme }) => theme.white};
  overflow: hidden;
  position: relative;
`

export const ParticleLayer = styled(Particles)`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`

export const Container = styled.div`
  max-width: ${dimensions.contentWidth};
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 64px 0;

  @media (max-width: ${breakpoints.l}px) {
    padding: 48px 24px;
  }
`

export const TeamMembers = styled.div`
  max-width: 980px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 64px 0;

  @media (max-width: ${breakpoints.l}px) {
    justify-content: center;
    flex-direction: column;
    padding: 48px 0;
  }
`

export const MemberContainer = styled.div`
  display: ${({ show }) => (show ? "flex" : "none")};
  color: ${({ theme }) => theme.black};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  font-family: Nunito, sans-serif;
  z-index: 10;
  width: 100%;
  height: 100%;
`

export const MemberName = styled.h4`
  font-weight: bold;
  font-size: 20px;
  text-align: center;

  @media (max-width: ${breakpoints.m}px) {
    font-size: 18px;
  }
`

export const MemberPosition = styled.p`
  font-size: 14px;
  margin: 0;

  @media (max-width: ${breakpoints.m}px) {
    font-size: 12px;
  }
`

// Hexagon design
const hexTopBottom = `
  position: absolute;
  z-index: 1;
  width: 176.78px;
  height: 176.78px;
  overflow: hidden;
  transform: scaleY(0.5774) rotate(-45deg);
  background: inherit;
  left: 36.61px;

  @media (max-width: ${breakpoints.m}px){
    width: 113.14px;
    height: 113.14px;
    left: 23.43px;
  }
`

const hexTopBottomAfter = `
  content: "";
  position: absolute;
  width: 250px;
  height: 144.33756729740645px;
  transform: rotate(45deg) scaleY(1.7321) translateY(-72.1688px);
  transform-origin: 0 0;
  background: inherit;

  @media (max-width: ${breakpoints.m}px){
    width: 160px;
    height: 92.37604307034013px;
    transform: rotate(45deg) scaleY(1.7321) translateY(-46.1880px);
  }
`

export const Hexagon = styled.a`
  position: relative;
  width: 250px;
  height: 144.34px;
  margin: 72.17px 38px;
  background-image: ${({ overlay, image }) =>
    overlay ? "none" : `url(${image})`};
  background-color: ${({ theme }) => theme.primary};
  background-size: auto 288.6751px;
  background-position: center;

  @media (max-width: ${breakpoints.m}px) {
    width: 160px;
    height: 92.38px;
    margin: 46.19px 24px;
    background-size: auto 184.7521px;
  }

  :after {
    content: "";
    position: absolute;
    top: 0px;
    left: 0;
    width: 250px;
    height: 144.3376px;
    z-index: 2;
    background: inherit;

    @media (max-width: ${breakpoints.m}px) {
      top: 0px;
      width: 160px;
      height: 92.376px;
    }
  }
`

export const HexTop = styled.div`
  ${hexTopBottom}
  top: -88.3883px;

  @media (max-width: ${breakpoints.m}px) {
    top: -56.5685px;
  }

  :after {
    ${hexTopBottomAfter}
    background-position: center top;
  }
`

export const HexBottom = styled.div`
  ${hexTopBottom}
  bottom: -88.3883px;

  @media (max-width: ${breakpoints.m}px) {
    bottom: -56.5685px;
  }

  :after {
    ${hexTopBottomAfter}
    background-position: center bottom;
  }
`

export const HexaGap = styled.div`
  position: relative;
  width: ${({ half }) => (half ? "125px" : "250px")};
  margin: ${({ half, left, right }) => {
    if (half && left) {
      return "72.17px 38px 72.17px 0"
    }
    if (half && right) {
      return "72.17px 0 72.17px 38px"
    }
    return "72.17px 38px"
  }};
  height: 144.34px;

  @media (max-width: ${breakpoints.m}px) {
    width: 200px;
    margin: 0;
  }

  @media (max-width: ${breakpoints.s}px) {
    width: 120px;
  }
`

export const HexaRow = styled.div`
  display: flex;
  justify-content: space-between;
`
