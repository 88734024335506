import styled from "@emotion/styled"
import { breakpoints } from "../../../utils/styles"

export const Container = styled.div`
  background: ${({ theme }) => theme.black};
  overflow: hidden;
  position: relative;
  z-index: 1;
`

export const Video = styled.video`
  position: absolute;
  object-fit: cover;
  transform: translateX(-50%) translateY(-50%);
  top: 30%;
  left: 50%;
  width: 100%;
  height: 100%;
  z-index: -100;

  @media (max-width: ${breakpoints.l}px) {
    transform: translateX(-50%) translateY(-50%);
    top: 30%;
  }
`

export const Overlay = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
  height: 600px;
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.3));

  @media (max-width: ${breakpoints.l}px) {
    height: 600px;
  }
`

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${({ theme }) => theme.white};
  background: ${({ theme }) => theme.black};
  min-height: 200px;
  padding: 32px;

  @media (max-width: ${breakpoints.l}px) {
    padding: 24px;
  }

  @media (max-width: ${breakpoints.s}px) {
    flex-direction: column;
    align-items: flex-end;
  }
`

export const Title = styled.h1`
  font-size: 32px;
  max-width: 650px;
  margin: 0;

  @media (max-width: ${breakpoints.l}px) {
    font-size: 24px;
  }
`

export const SocialContainer = styled.div`
  display: flex;
  width: 300px
  justify-content: space-between;
  padding: 8px 16px;
`

export const SocialLink = styled.a`
  padding: 8px;
`

export const SocialIcon = styled.img`
  margin: 0;
`

export const ArrowDown = styled.img`
  width: 64px;
  height: 64px;
  margin-bottom: 0;
  padding: 16px;

  @media (max-width: ${breakpoints.l}px) {
    width: 42px;
    height: 42px;
  }
`
